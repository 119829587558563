@import 'https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap';
#root {
  /* ! root element containing react components ! */
  height: 100vh;
  width: 100vw;
}
body {
  font-family: 'Cardo', serif;
  font-size: 18px;
  background-color: #111;
  background-position: center;
  color: #fff;
  margin: 0;
  overflow: hidden;
  transition: opacity 0.1s;
  display: grid;
  place-content: center;
}
a {
  text-decoration: underline;
  color: #fe938c;
}
a:visited {
  color: #999;
}
h1 {
  font-family: 'Cardo', serif;
}
.texture-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./images/rocklina-bg.png");
  z-index: -1;
}
.page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.web-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  place-items: center;
  place-content: center;
  flex-wrap: wrap;
}
.centered-horizontal {
  width: 100%;
  display: flex;
  justify-content: center;
}
.font1 {
  font-family: 'Cardo', serif;
}
.font2 {
  font-family: 'Cardo', serif;
}
.component-container {
  border: 1px solid #666 !important;
  background-color: #222;
  border-radius: 8px;
  margin-top: 10px;
  padding: 24px;
  width: auto !important;
  min-width: auto;
}
.component-container-fixed-width {
  border: 1px solid #666 !important;
  background-color: #222;
  border-radius: 8px;
  margin-top: 10px;
  padding: 24px;
  width: auto !important;
  min-width: 800px;
}
@media (max-width: 1200px) {
  .component-container-fixed-width {
    border: 1px solid #666 !important;
    background-color: #222;
    border-radius: 8px;
    margin-top: 10px;
    padding: 24px;
    width: auto !important;
    min-width: 0px;
  }
}
@media (max-width: 992px) {
  .component-container-fixed-width {
    border: 1px solid #666 !important;
    background-color: #222;
    border-radius: 8px;
    margin-top: 10px;
    padding: 24px;
    width: auto !important;
    min-width: 0px;
  }
}
@media (max-width: 768px) {
  .component-container-fixed-width {
    border: 1px solid #666 !important;
    background-color: #222;
    border-radius: 8px;
    margin-top: 10px;
    padding: 24px;
    width: auto !important;
    min-width: 0px;
  }
}
.hide-on-mobile {
  display: none !important;
}
.button {
  font-size: 10px;
  color: #aaa !important;
  background-color: #333 !important;
  border: #666 solid 1px !important;
  margin: 5px !important;
  box-shadow: 0px 0px 0px inset #333, 0px 0px 0px 0px inset #333 !important;
  transition: box-shadow 0.1s;
}
.button:enabled:hover {
  cursor: pointer;
  box-shadow: 0px -8px 0px inset #444, 0px 8px 0px 0px inset #444 !important;
}
.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  flex-wrap: wrap;
}
.data-row span {
  color: #aaa;
}
.block {
  margin-top: 10px;
}
.MuiTypography-h4 {
  color: #fe938c;
  font-family: 'Metamorphous' !important;
  text-shadow: 2px 2px 2px #646464 !important;
}
.MuiTypography-h5 {
  font-family: 'Cardo' !important;
  margin-top: 10px !important;
}
.MuiTypography-h6 {
  font-family: 'Cardo' !important;
}
.right-aligned {
  text-align: right;
  text-decoration: none !important;
}
.right-aligned a {
  color: #fe938c;
}
.right-aligned a:visited {
  color: #fe938c;
}
.icon {
  color: #fe938c !important;
}
.MuiDialog-paper,
.MuiMenu-paper {
  background-color: #222 !important;
  font-family: 'Cardo' !important;
  color: #fff !important;
}
.dropdown,
.table-filter {
  background-color: #333;
  border: 1px solid #666;
  border-radius: 4px;
  overflow: hidden;
  margin: 5px;
}
.dropdown .MuiInputBase-input,
.table-filter .MuiInputBase-input {
  color: #fe938c !important;
}
.dropdown .MuiAutocomplete-inputRoot,
.table-filter .MuiAutocomplete-inputRoot {
  padding: 0px !important;
}
.dropdown .MuiInputBase-input::placeholder,
.table-filter .MuiInputBase-input::placeholder {
  color: #fff !important;
}
.dropdown .MuiAutocomplete-tag,
.table-filter .MuiAutocomplete-tag {
  color: #fff;
  background-color: #fe938c;
}
.dropdown .MuiAutocomplete-clearIndicator,
.table-filter .MuiAutocomplete-clearIndicator,
.dropdown .MuiAutocomplete-popupIndicator,
.table-filter .MuiAutocomplete-popupIndicator {
  color: #fe938c;
}
.MuiOutlinedInput-root {
  border: 1px solid #666;
  background-color: #333;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3030d6 !important;
}
.MuiInputBase-input {
  color: #fff !important;
}
.MuiFormLabel-root {
  color: #aaa !important;
}
.MuiCheckbox-root {
  color: #3030d6 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #333 inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff !important;
}
input:-moz-ui-autofill,
input:-moz-ui-autofill:focus {
  background: #333 !important;
  color: #fff !important;
}
.link {
  cursor: pointer;
}
.Mui-disabled {
  color: #aaa !important;
  -webkit-text-fill-color: #aaa !important;
  /* Override for iOS */
}
.MuiDivider-root {
  background-color: #666 !important;
}
.title-form-checkbox {
  padding-left: 5px;
  color: #aaa !important;
}
