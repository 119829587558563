.table {
  border: 1px solid #666;
  border-radius: 8px;
  border-collapse: separate !important;
}
th,
td {
  color: #ddd !important;
  border: none !important;
}
tr {
  background-color: #191919;
}
tr:nth-child(even) {
  background-color: #222 !important;
}
tr:hover {
  background-color: #272727 !important;
}
.search-bar-container {
  padding-right: 10px;
}
.MuiTextField-root.search-bar {
  box-sizing: border-box;
  font-family: 'Cardo', serif;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  border: #666 solid 1px;
  margin: 5px;
}
.filter-selector {
  border-left: none;
  border-right: none;
  margin-bottom: 10px;
  color: #aaa;
}
.filter-table-section {
  margin-bottom: 10px;
  color: #fff;
  border: 1px solid #666 !important;
  border-radius: 8px;
  padding: 5px;
  padding-right: 10px;
}
.MuiTable-root {
  user-select: none;
  width: 100%;
  background-color: #222;
}
.search-bar .MuiInputBase-input {
  padding: 5px !important;
}
.MuiInputBase-input {
  color: #ddd !important;
}
.MuiTableCell-head {
  user-select: none;
  font-family: 'Cardo', serif;
  font-weight: bold;
  font-size: 20px;
  background-color: #333;
  color: #fff;
  padding: 12px 10px 10px;
  text-align: left;
  border-bottom: 2px solid #666;
  cursor: default;
}
.MuiTableCell-body {
  font-size: 16px;
  user-select: text;
  font-family: 'Cardo', serif;
  padding: 10px;
  border-bottom: 1px solid #666;
  color: #fff;
}
.MuiTableRow-root:hover {
  background-color: #333;
  cursor: pointer;
}
.table-navigator .MuiButton-root {
  font-family: 'Cardo', serif;
  font-size: 10px;
  color: #fff;
  background-color: #222;
  border: #333 solid 1px;
  min-width: 25px;
  box-shadow: 0px 0px 0px inset #333, 0px 0px 0px 0px inset #333;
  transition: box-shadow 0.1s;
}
.table-navigator {
  margin-top: 5px;
  border-top: #666 solid 1px;
}
.table-navigator .MuiButton-root:disabled {
  background-color: #222 !important;
  color: #666;
}
.table-navigator .MuiButton-root:enabled:hover {
  cursor: pointer;
  box-shadow: 0px -8px 0px inset #333, 0px 8px 0px 0px inset #333;
}
.table-navigator .MuiButton-root.active {
  background-color: #111 !important;
  color: #fe938c;
}
/* Adjusting styles for Material-UI's TextField component */
.table-navigator .MuiTextField-root {
  font-family: var(--font-1);
  font-size: 10px;
  color: #fff;
  background-color: #333;
  border: #666 solid 1px;
  width: 50px;
}
.table-navigator .MuiTextField-root.Mui-error {
  color: #f4305a;
  text-decoration: line-through;
}
.table-header-sort {
  cursor: pointer;
}
.table-navigator-text {
  color: #fff !important;
  margin-top: 5px !important;
  margin-right: 5px !important;
}
.table-navigator-page {
  margin-top: 5px !important;
  width: 60px !important;
}
.table-navigator-page input {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.table-underlined {
  text-decoration: underline;
}
.table-title {
  font-family: 'Cardo', serif;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}
.MuiTableCell-head {
  font-weight: bold !important;
  font-size: 15px !important;
}
