.centered {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  overflow-y: auto;
}
.column-down {
  /*max-height: 100%;*/
  display: flex;
  flex-direction: column;
  padding: 15px;
}
