@import 'https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap';
.navbar {
  user-select: none;
  position: static;
  width: 100%;
  display: flex;
  align-items: left;
  border-bottom: solid 2px #666;
  background-color: transparent !important;
  z-index: 1;
  box-shadow: 0px 2px 0px 0px #111;
  flex-shrink: 0;
}
.navbar .navbar-description {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #aaa !important;
  display: block;
}
.navbar .navbar-item {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #777 !important;
  height: 100%;
  display: flex;
  transition: height 0.1s, box-shadow 0.1s;
  text-decoration: none;
}
.navbar .navbar-item:hover {
  box-shadow: 0px 0px 0px 0px inset #444, 1px 1px 1px 1px inset #444;
  cursor: pointer;
}
.navbar-offset {
  margin-top: 50px;
}
.login-dialog-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: #666 solid !important;
}
.login-dialog-textfield label {
  color: #666 !important;
}
.MuiButton-textPrimary {
  color: #fe938c !important;
}
.user-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-icon-text {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #777 !important;
}
