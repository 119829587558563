.form-item {
  margin-top: 0px;
  margin-bottom: 0px;
}
.form {
  background-color: #191919;
  padding-top: 20px;
  margin-top: 10px;
  border: 1px solid #666;
  border-radius: 8px;
  border-collapse: separate !important;
}
.form-dropdown {
  margin-bottom: 20px;
}
.form-dropdown .MuiInputBase-input {
  color: #fe938c !important;
}
.form-dropdown .MuiAutocomplete-inputRoot {
  padding: 0px !important;
}
.form-dropdown .MuiInputBase-input::placeholder {
  color: #fff !important;
}
.form-dropdown .MuiAutocomplete-tag {
  color: #fff;
  background-color: #fe938c;
}
.form-dropdown .MuiAutocomplete-clearIndicator,
.form-dropdown .MuiAutocomplete-popupIndicator {
  color: #fe938c;
}
button:disabled {
  color: #777 !important;
}
.form-error-message {
  color: #d32f2f;
  font-size: 0.75rem;
  margin-top: -20px;
}
.error-message {
  color: #f4305a;
}
.form-date-picker {
  margin-top: 0px;
  margin-right: 20px !important;
  margin-bottom: 20px !important;
}
