.table-filter-quick {
  display: inline-block;
  margin-right: 5px !important;
  margin-left: 5px !important;
}
.table-filter-quick .MuiInputBase-input {
  color: #fe938c !important;
}
.table-filter-quick .MuiInputBase-input::placeholder {
  color: #fff !important;
}
.table-filter-quick input {
  font-family: 'Cardo' !important;
}
.table-filter-quick .MuiAutocomplete-clearIndicator,
.table-filter-quick .MuiAutocomplete-popupIndicator {
  color: #fe938c;
}
.date-picker {
  border: 1px solid #666 !important;
  background-color: #333;
  border-radius: 4px;
  margin-left: 5px !important;
  color: #fe938c !important;
}
.date-picker label {
  color: #999 !important;
}
.date-picker .MuiIconButton-root {
  color: #fe938c !important;
}
.MuiAutocomplete-option {
  color: #aaa;
  padding: 5px 10px;
}
.date-picker .MuiInputBase-input {
  color: #fe938c !important;
}
.MuiAutocomplete-listbox,
.MuiAutocomplete-noOptions {
  background-color: #333 !important;
  color: #aaa !important;
}
.MuiAutocomplete-option:hover {
  background-color: #666 !important;
}
.MuiAutocomplete-groupLabel {
  background-color: #444 !important;
  color: #aaa !important;
  font-size: 16px !important;
}
.MuiToggleButtonGroup-root {
  margin-right: 5px;
  margin-bottom: 5px;
}
.MuiToggleButton-standard {
  background-color: #333 !important;
  color: #aaa !important;
  border: 1px solid #666 !important;
}
.Mui-selected {
  background-color: #666 !important;
  color: #aaa !important;
}
